<template>
  <div style="padding: 24px 16px; height: 100%">
    <div class="mission-content full column">
      <div class="header column">
        <div class="flex flex-wrap">
          <div class="search-item">
            车牌号：
            <a-input v-model:value="license_card" placeholder="请输入" allowClear/>
          </div>
          <div class="search-item">
            所属企业：
            <a-input v-model:value="company_name" placeholder="请输入" allowClear/>
          </div>
            <div class="search-item">
            设备商：
            <a-select
              v-model:value="device_supplier"
              style="flex: 1"
              ref="select"
              allowClear
              @change="supplierChange"
            >
               <a-select-option
                  v-for="( value , index ) in suppliers" 
                  :key="index" 
                  :value="value.supplier"
                > 
                  {{value.supplier}}
              </a-select-option>
            </a-select>
          </div>
          <div class="search-item">
            设备型号：
            <a-select
              v-model:value="device_type"
              style="flex: 1"
              ref="select"
              allowClear
              :disabled="type_dis"
              @change="typeChange"
            >
               <a-select-option 
                  v-for="( value , index ) in device_types" 
                  :key="index" 
                  :value="value.type"
                > 
                  {{value.type}}
              </a-select-option>
            </a-select>
          </div>
            <div class="search-item">
            软件版本号：
            <a-select
              v-model:value="device_version"
              style="flex: 1"
              ref="select"
              allowClear
              :disabled="version_dis"
            >
               <a-select-option
                  v-for="( value , index ) in versions" 
                  :key="index" 
                  :value="value.version"
                > 
                  {{value.version}}
              </a-select-option>
            </a-select>
          </div>
          <div class="search-item">
            任务类型：
            <a-select
              v-model:value="type"
              style="flex: 1"
              ref="select"
              allowClear
            >
              <a-select-option 
                  key="参数下发" 
                  value="1"
                > 
                  参数下发
              </a-select-option>
               <a-select-option 
                  key="参数请求" 
                  value="2"
                > 
                  参数请求
              </a-select-option>
            </a-select>
          </div>
             <div class="search-item">
            任务状态：
            <a-select
              v-model:value="status"
              style="flex:1"
              ref="select"
              allowClear
            >
               <a-select-option 
                  key="进行中" 
                  value="0"
                > 
                  进行中
              </a-select-option>
               <a-select-option 
                  key="成功" 
                  value="1"
                > 
                  成功
              </a-select-option>
              <a-select-option 
                  key="失败" 
                  value="2"
                > 
                  失败
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="flex">
          <div class="search-item" style="width: 50%">
            创建日期：
            <a-range-picker
              class="flex1"
              v-model:value="moment"
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM-DD HH:mm:ss"
              key="dateRange"
              :onChange="momentChange"
            />
          </div>
          <div class="search-button flex1">
            <button class="blue-button" @click="search">查询</button>
          </div>
        </div>
      </div>
      <div class="flex1" id="table">
        <a-table
          :columns="columns"
          :data-source="list"
          :scroll="{ x: 1300, y: height }"
          class="full"
          :pagination="pagination"
          @change="fresh"
        >
          <template #radius="{text, record}">
              <div class="radius" v-if="record.status === '失败'">
                <span :style="{color: '#F5222D'}">•</span>
                <label>失败</label>
                <a-tooltip>
                  <template #title>
                    {{ record.remark }}
                  </template>
                  <img class="errIcon" :src="require('../../assets/i.png')" alt="">
                </a-tooltip>
              </div>
              <div class="radius" v-if="record.status === '等待'">
                <span :style="{color: '#1890FF'}">•</span>
                <label>等待</label>
              </div>
              <div class="radius" v-if="record.status === '成功'">
                <span :style="{color: '#52C41A'}">•</span>
                <label>成功</label>
              </div>
          </template>
          <template #action="{text, record}">
            <div class="flex" v-if="record.type === '参数请求'">
              <a-button @click="active(record.id,record.type)" type="link" v-if="record.status === '失败'" disabled>查看结果</a-button>
              <a-button @click="active(record.id,record.type)" type="link" v-else>查看结果</a-button>

            </div>
            <div class="flex" v-else>
              <a-button @click="active(record.id,record.type)" type="link">查看下发参数</a-button>
            </div>
          </template>
          <template #action2="{ text, record }">
            <div class="flex">
              {{record.type}}({{record.update_time}})
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal
        :title="title"
        v-model:visible="visible"
        :confirm-loading="confirmLoading"
        ok-text="确认"
        cancel-text="取消"
        :onOk="handleOk"
        :onCancel="cancel"
      >
        <div v-html="jsonData">
          
        </div>
      </a-modal>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { DatePicker, Modal, Tooltip } from 'ant-design-vue';

import {
  GetModelParam,
  TaskSimple,
  MissionListService
} from './mission-list.service'
@Options({
  components: {
    'a-range-picker': DatePicker.RangePicker,
    "a-modal": Modal,
    "a-tooltip": Tooltip
  },
})
export default class MissionList extends Vue {
  public suppliers: [] = []; // 设备商列表
  public device_types: [] = []; // 设备型号列表
  public versions: [] = []; // 软件版本号列表
  public license_card:string = '';//车牌号
  public company_name:string = '';//企业
  public device_supplier:string = '';// 设备商
  public device_type:string ='';//设备型号
  public device_version:string='';//设备版本
  public type:string = '';//任务类型
  public moment = [];
  public start_date = '';//创建开始时间
  public end_date = '';//创建结束时间
  public status = '';//任务状态
  public update_time='';//更新时间
  public type_dis: boolean = true;
  public version_dis: boolean = true;
  public value1 = new Date();
  public height = 300;
  public visible: boolean = false;
  public title: string = '';
  public info = '';
  public jsonData: string = '';
  public pagination = {
    current: 2,
    pageSize: 10,
    total: 100,
  };
  public columns = [
    {
      title: '车牌号',
      width: 150,
      dataIndex: 'license_card',
      key: '1',
      // fixed: 'left',
    },
    {
      title: '车牌颜色',
      width: 100,
      dataIndex: 'license_color',
      key: '2',
      // fixed: 'left',
    },
    { title: '所属企业', dataIndex: 'company_name', key: '3', width: 150 },
    { title: '设备商', dataIndex: 'device_supplier', key: '4', width: 150 },
    {title:'设备型号',dataIndex:'device_type', key: '4', width: 150},
    {
      title: '任务类型（创建时间）',
      dataIndex: 'start_date',
      key: '5',
      width: 250,
      slots: { customRender: 'action2' },
    },
    { title: '任务状态', dataIndex: 'status', key: '6', width: 150, slots: { customRender: 'radius' },},
    { title: '更新时间', dataIndex: 'update_time', key: '7', width: 200 },
    {
      title: '操作',
      key: '8',
      dataIndex: 'id',
      // fixed: 'right',
      width: 150,
      slots: { customRender: 'action' },
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public list: TaskSimple[] = [];
  public param: GetModelParam = new GetModelParam();
  // 获取设备型号列表
  public async gettypes() {
    const data: [] = await this.$http
      .get( `${process.env.VUE_APP_API}/devices/types`, {
        supplier: this.device_supplier
      }); // 接口
    this.device_types = data;
  }

  public handleOk() {
    this.visible = false;
  }

  public cancel() {
    this.visible = false;
  }

  public async supplierChange() {
    if (!this.device_supplier) {
      this.device_type = '';
      this.type_dis = true;
      this.device_version = '';
      this.version_dis = true;
      return;
    }
    await this.gettypes();
    this.type_dis = false;
    this.device_type = '';
    this.device_version = '';
    this.version_dis = true;
  };

  public async typeChange() {
    if (!this.device_type) {
      this.device_version = '';
      this.version_dis = true;
      return
    }
    await this.getVersions();
    this.version_dis = false;
  }

  public async getSuppliers() {
    const data: [] = await this.$http
      .get( `${process.env.VUE_APP_API}/devices/suppliers`, {}); // 接口
    this.suppliers = data;
  }

  public async getVersions() {
    const data: [] = await this.$http
      .get( `${process.env.VUE_APP_API}/devices/versions`, {
        supplier: this.device_supplier,
        type: this.device_type
      }); // 接口
    this.versions = data;
  }

  public mounted() {
    this.$storage.get<number>('height-mission-list').then( (value) => {
      this.height = value ? value : 300;
    });
    window.onresize = this.checkHeight;
    this.getSuppliers();
    this.search();
  }

  public momentChange(date:[], dateString:Array<string>) {
    this.start_date = dateString[0];
    this.end_date = dateString[1];
  }
  public search(){
    this.param.license_card = this.license_card; // 车牌号
    this.param.company_name = this.company_name; // 所属企业
    this.param.device_supplier = this.device_supplier;
    this.param.device_version = this.device_version;
    this.param.device_type = this.device_type;
    this.param.start_date = this.start_date;
    this.param.end_date = this.end_date;
    this.param.status = this.status;
    this.param.type = this.type;
    this.pagination.current = 1;
    this.fresh(this.pagination);
  }
      public async fresh(page: {
    current: number;
    pageSize: number;
    total: number;
  }) {
    this.pagination = page;
    this.param.page_number = page.current;
    this.param.page_size = page.pageSize;
    this.$base.loading.show();
    // tslint:disable-next-line:max-line-length
    const http = await this.$http
      .get<{ list: TaskSimple[]; total: number }>(
        `${process.env.VUE_APP_API}/tasks`,
        this.param,
      )
      .then(
        (r) => {
          this.list = r.list;
          this.pagination.total = r.total;
          this.checkHeight();
        },
        (e) => {
          this.$base.error(e);
        },
      );
    this.checkHeight();
    this.$base.loading.close();
  }
  public checkHeight() {
    const table = document.querySelector('#table');
    this.height = table ? table.clientHeight - 64 - 54 - 20 : 300;
    this.$storage.save('height-mission-list', this.height);
  }

  public writeHtml(obj: object | any[], name?: string) {
    console.log(this.$refs, 'this.$refs.result');
    // console.log('obj', obj);
    const objStr = JSON.stringify(obj, null, 4);
    // console.log('objStr', objStr);
    const html = objStr.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp');
    // console.log('html', html);
    this.jsonData = html;
  }

  public async active(id:string, type:string) {
    //出现一个弹框 里面是json对象  html格式化json输出 /tasks/{id}
    const http = await this.$http
      .get<{send_message: string, receive_message: string}>(
        `${process.env.VUE_APP_API}/tasks/${id}`, {}
      ).then((r)=> {
        this.visible = true;
        if (type === '参数下发') {
          this.title = '查看下发参数'
          this.writeHtml(JSON.parse(r.send_message))
        } else {
          this.title = '查看结果'
          this.writeHtml(JSON.parse(r.receive_message));
        }
        // else if(type === '参数请求'&&status==='失败'){
        //   this.visible=false;
        //   this.visible.style.color='#ccc'
        // }
      })
  }
    
  public onChange(date: Date, dateString: string) {
    console.log(date, dateString);
  }
}
</script>
<style lang="less">
.mission-content {
  background: unset;
  padding: 0px;
  .header {
    background: white;
    margin-bottom: 24px;
    border-radius: 2px;
    justify-content: space-between;
    padding: 10px 25px;
    .search-item {
      width: 25%;
      margin: 0px;
      padding-right: 10px;
      input {
        flex: 1;
      }
    }
    .search-button {
      justify-content: flex-end;
      padding-right: 5px;
    }
  }
  #table {
    background: white;
    border-radius: 2px;
    padding: 10px 25px;
  }
  .radius {
    display: flex;
    align-items: center;
    span {
      display: block;
      font-size: 15px;
      margin-right: 5px;
    }
    .errIcon {
      width: 12px;
      height: 12px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
</style>