import { AxiosPromise } from 'axios';
import Http from '@/core/http.service';
export class GetModelParam {
    public license_card: string | undefined;
    public company_name: string | undefined;
    public status: string | undefined;
    public device_type:string|undefined;
    public device_supplier:string|undefined;
    public device_version:string|undefined;
    public type:string|undefined;
    public start_date:string|undefined;
    public end_date:string|undefined;
    public page_number: number | undefined;
    public page_size: number | undefined;
}
export class TaskSimple {
    public id: number | undefined;
    public license_card: string | undefined;
    public license_color:string|undefined;
    public industry: string | undefined;
    public device_supplier:string|undefined;
    public start_date:string|undefined;
    public status:string|undefined;
    public update_time:string|undefined;
    
}
export class MissionListService {
    public baseUrl: string = process.env.VUE_APP_API;
    // tslint:disable-next-line:member-access
    $http = Http;
    public getModelList(param: GetModelParam): AxiosPromise<{list: TaskSimple[], total: number}> {
        return this.$http.get(`${process.env.VUE_APP_API}/tasks`, param);
    }
}