
import { Options, Vue } from 'vue-class-component';
import { DatePicker, Modal, Tooltip } from 'ant-design-vue';

import {
  GetModelParam,
  TaskSimple,
  MissionListService
} from './mission-list.service'
@Options({
  components: {
    'a-range-picker': DatePicker.RangePicker,
    "a-modal": Modal,
    "a-tooltip": Tooltip
  },
})
export default class MissionList extends Vue {
  public suppliers: [] = []; // 设备商列表
  public device_types: [] = []; // 设备型号列表
  public versions: [] = []; // 软件版本号列表
  public license_card:string = '';//车牌号
  public company_name:string = '';//企业
  public device_supplier:string = '';// 设备商
  public device_type:string ='';//设备型号
  public device_version:string='';//设备版本
  public type:string = '';//任务类型
  public moment = [];
  public start_date = '';//创建开始时间
  public end_date = '';//创建结束时间
  public status = '';//任务状态
  public update_time='';//更新时间
  public type_dis: boolean = true;
  public version_dis: boolean = true;
  public value1 = new Date();
  public height = 300;
  public visible: boolean = false;
  public title: string = '';
  public info = '';
  public jsonData: string = '';
  public pagination = {
    current: 2,
    pageSize: 10,
    total: 100,
  };
  public columns = [
    {
      title: '车牌号',
      width: 150,
      dataIndex: 'license_card',
      key: '1',
      // fixed: 'left',
    },
    {
      title: '车牌颜色',
      width: 100,
      dataIndex: 'license_color',
      key: '2',
      // fixed: 'left',
    },
    { title: '所属企业', dataIndex: 'company_name', key: '3', width: 150 },
    { title: '设备商', dataIndex: 'device_supplier', key: '4', width: 150 },
    {title:'设备型号',dataIndex:'device_type', key: '4', width: 150},
    {
      title: '任务类型（创建时间）',
      dataIndex: 'start_date',
      key: '5',
      width: 250,
      slots: { customRender: 'action2' },
    },
    { title: '任务状态', dataIndex: 'status', key: '6', width: 150, slots: { customRender: 'radius' },},
    { title: '更新时间', dataIndex: 'update_time', key: '7', width: 200 },
    {
      title: '操作',
      key: '8',
      dataIndex: 'id',
      // fixed: 'right',
      width: 150,
      slots: { customRender: 'action' },
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public list: TaskSimple[] = [];
  public param: GetModelParam = new GetModelParam();
  // 获取设备型号列表
  public async gettypes() {
    const data: [] = await this.$http
      .get( `${process.env.VUE_APP_API}/devices/types`, {
        supplier: this.device_supplier
      }); // 接口
    this.device_types = data;
  }

  public handleOk() {
    this.visible = false;
  }

  public cancel() {
    this.visible = false;
  }

  public async supplierChange() {
    if (!this.device_supplier) {
      this.device_type = '';
      this.type_dis = true;
      this.device_version = '';
      this.version_dis = true;
      return;
    }
    await this.gettypes();
    this.type_dis = false;
    this.device_type = '';
    this.device_version = '';
    this.version_dis = true;
  };

  public async typeChange() {
    if (!this.device_type) {
      this.device_version = '';
      this.version_dis = true;
      return
    }
    await this.getVersions();
    this.version_dis = false;
  }

  public async getSuppliers() {
    const data: [] = await this.$http
      .get( `${process.env.VUE_APP_API}/devices/suppliers`, {}); // 接口
    this.suppliers = data;
  }

  public async getVersions() {
    const data: [] = await this.$http
      .get( `${process.env.VUE_APP_API}/devices/versions`, {
        supplier: this.device_supplier,
        type: this.device_type
      }); // 接口
    this.versions = data;
  }

  public mounted() {
    this.$storage.get<number>('height-mission-list').then( (value) => {
      this.height = value ? value : 300;
    });
    window.onresize = this.checkHeight;
    this.getSuppliers();
    this.search();
  }

  public momentChange(date:[], dateString:Array<string>) {
    this.start_date = dateString[0];
    this.end_date = dateString[1];
  }
  public search(){
    this.param.license_card = this.license_card; // 车牌号
    this.param.company_name = this.company_name; // 所属企业
    this.param.device_supplier = this.device_supplier;
    this.param.device_version = this.device_version;
    this.param.device_type = this.device_type;
    this.param.start_date = this.start_date;
    this.param.end_date = this.end_date;
    this.param.status = this.status;
    this.param.type = this.type;
    this.pagination.current = 1;
    this.fresh(this.pagination);
  }
      public async fresh(page: {
    current: number;
    pageSize: number;
    total: number;
  }) {
    this.pagination = page;
    this.param.page_number = page.current;
    this.param.page_size = page.pageSize;
    this.$base.loading.show();
    // tslint:disable-next-line:max-line-length
    const http = await this.$http
      .get<{ list: TaskSimple[]; total: number }>(
        `${process.env.VUE_APP_API}/tasks`,
        this.param,
      )
      .then(
        (r) => {
          this.list = r.list;
          this.pagination.total = r.total;
          this.checkHeight();
        },
        (e) => {
          this.$base.error(e);
        },
      );
    this.checkHeight();
    this.$base.loading.close();
  }
  public checkHeight() {
    const table = document.querySelector('#table');
    this.height = table ? table.clientHeight - 64 - 54 - 20 : 300;
    this.$storage.save('height-mission-list', this.height);
  }

  public writeHtml(obj: object | any[], name?: string) {
    console.log(this.$refs, 'this.$refs.result');
    // console.log('obj', obj);
    const objStr = JSON.stringify(obj, null, 4);
    // console.log('objStr', objStr);
    const html = objStr.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp');
    // console.log('html', html);
    this.jsonData = html;
  }

  public async active(id:string, type:string) {
    //出现一个弹框 里面是json对象  html格式化json输出 /tasks/{id}
    const http = await this.$http
      .get<{send_message: string, receive_message: string}>(
        `${process.env.VUE_APP_API}/tasks/${id}`, {}
      ).then((r)=> {
        this.visible = true;
        if (type === '参数下发') {
          this.title = '查看下发参数'
          this.writeHtml(JSON.parse(r.send_message))
        } else {
          this.title = '查看结果'
          this.writeHtml(JSON.parse(r.receive_message));
        }
        // else if(type === '参数请求'&&status==='失败'){
        //   this.visible=false;
        //   this.visible.style.color='#ccc'
        // }
      })
  }
    
  public onChange(date: Date, dateString: string) {
    console.log(date, dateString);
  }
}
